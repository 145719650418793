.MuiFormControl-root {
  margin: 16px 0;
}
.MuiInputBase-root,
.MuiFormControl-root {
  width: 100%;
  color: white;
  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root-Mui-disabled,
  label,
  input {
    color: white;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $formBorderColour;
  }
  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $formBorderColourHover;
      transition: border 0.1s ease-in;
    }
  }
  &.Mui-disabled {
    color: $textDisabledColour;
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $formBorderDisabledColour;
    }
  }
  &.-inputMultiline {
    white-space: pre-wrap;
  }
  &.Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $formBorderColourHover;
    }
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    color: white;
  }
  .MuiFormLabel-root {
    &.Mui-disabled {
      color: white;
    }
  }
}

// override datePicker error styling
.MuiFormControl-root {
  p.Mui-error {
    margin-top: 0;
  }
}
