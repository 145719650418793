.confirm-delete {
  justify-content: flex-start;
  margin: 0;
  width: 100%;
  max-height: 100vh;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin: 15px 0;
    border-bottom: 1px solid $underlineGrey;

    h6 {
      margin: 40px 0 10px;
      text-align: left;
      font-weight: 600;
      line-height: 1rem;
      color: $callToActionColour;
      width: 100%;
    }

    .button {
      margin: 10px 0;
      line-height: 1rem;
    }
  }

  &__table-heading {
    position: static;
  }

  &__heading-container {
    margin-bottom: 10px;
  }

  &__heading-button-container {
    width: 350px;
    display: flex;
    justify-content: flex-end;

    button:nth-of-type(2) {
      margin-left: 10px;
    }
  }

  &__sub-heading-container {
    display: block;
    margin: 10px 0;
    flex: 0 0 100%;
  }

  &__table-container {
    overflow-x: auto;
    height: calc(100vh - 325px);
  }

  &__table-row {
    height: 47px;
  }

  &__table-container-contents {
    max-width: 768px;

    &.--two-column {
      max-width: 550px;
    }
  }

  .dropdown-menu {
    &.--manage-users {
      position: absolute;
      right: 10px;
      top: 50px;
      z-index: 1;
    }

    .dropdown-option {
      box-shadow: none;
      &.--disable-hover-highlight:hover {
        background-color: white;
      }
    }
  }

  .filter-text-input {
    height: 42px;
    width: 400px;
    max-width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    border: none;
  }
}
