.mobile-nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  top: 0;
  left: -650px;
  background: $navBackground;
  height: 100%;
  width: 100%;
  overflow: hidden;
  transition: left 100ms ease-in;
  z-index: 3;

  &__nav-icon {
    height: 18.4px;
    display: inline-block;
    position: absolute;
    margin-left: 10px;
    fill: $callToActionColour;
  }

  header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $mobileNavMenuHeight;
    margin-bottom: 20px;
    -webkit-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.28);
    box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.28);
    h2 {
      margin: 0;
      color: white;
    }
    button.hamburger {
      position: absolute;
      right: 3px;
      top: 12px;
    }
  }
  .mobile-nav__inner {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      li {
        border-bottom: 1px solid rgb(143, 143, 143);
        a {
          padding: 10px 0;
          text-decoration: none;
          color: white;
          font-size: 1rem;
          text-align: center;
          cursor: pointer;
        }
        button {
          padding: 10px 0;
          text-decoration: none;
          width: 100%;
          color: white;
          font-size: 1rem;
          text-align: center;
          background-color: $navBackground;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}
