.form {
  p.form__error-message,
  .MuiFormHelperText-root.Mui-error {
    margin: 0px;
    margin-top: -16px;
    display: block;
    padding: 0 3px;
    width: 100%;
    background-color: $errorMessageBackground;
    border: 1px solid $errorMessageBackground;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: left;
    color: $textErrorColour;
    font-size: 0.7rem;
    font-weight: 800;
    &:before {
      display: inline;
      margin-right: 5px;
      content: '⚠';
      color: $textErrorColour;
    }
  }
}
