main.connect-users-view {
  .container {
    header {
      h2 {
        margin: 40px 0 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #6d6d6d;
        width: 100%;
        text-align: left;
        font-size: 1.6rem;
        font-weight: 600;
        color: $textPrimary;
      }
    }
  }
  .container-flex {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .connect-users-view__split-table-container {
    display: flex;
    justify-content: space-between;
    .connect-users-view__table-container {
      width: 49%;
      .connect-users-view__table-container-contents {
        h3 {
          font-size: 1.6rem;
          font-weight: 500;
          color: $textPrimary;
        }
        .mui-table {
          width: 100%;
          max-width: 100%;
          max-height: 70vh;
          overflow: scroll;
        }
      }
    }
  }
  .connect-users-view__user-type-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    button.button {
      margin: 20px;
    }
  }
}

@media (max-width: $smallDesktopWidth) {
  main.connect-users-view {
    .connect-users-view__split-table-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .connect-users-view__table-container {
        width: 100%;
        margin-top: 40px;
      }
    }
  }
}
