header.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $headerBackgroundLight;
  height: $desktopHeaderHeight;
  width: 100%;
  padding: 0 20px;
  font-size: calc(8px + 1vmin);
  color: $headerTextColourDark;
  z-index: 3;
  .main-header__header-left-side {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    a.button-with-img {
      background: none;
      margin: 0;
      padding: 0px;
      padding-right: 20px;
      width: 75px;
      border-right: 1px solid $headerBorderColour;
      border-radius: 0;
      &:hover {
        opacity: 0.6;
      }
      img {
        width: 100%;
      }
    }
    h1.header-title {
      width: 100%;
      font-weight: 400;
      padding-left: 20px;
      font-size: 1rem;
      text-align: left;
    }
  }
  .main-header__header-right-side {
    height: 100%;
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      height: 100%;
      display: flex;
      align-items: center;
    }

    button.main-header__header-button {
      position: relative;
      margin: 0;
      padding: 0 20px;
      border-left: 1px solid $headerBorderColour;
      border-radius: 0;
      width: 100px;
      max-width: 90px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      box-shadow: none;
      svg {
        display: block;
        width: 40px;
        max-width: 40px;
        height: 100%;
        fill: #a3a3a3;
      }
      &:hover {
        cursor: pointer;
        svg {
          fill: $ctaNotification;
          transform: scale(1.1);
          transition: all 0.3s ease;
        }
      }
      &.--active {
        background: $pageBackground;
        svg {
          fill: $callToActionColour;
        }
      }
      span.notification-dot {
        content: '';
        position: absolute;
        top: 15px;
        right: 25px;
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $notificationButtonDotColour;
      }
    }

    .main-header__button-notification {
      border-right: 1px solid $headerBorderColour;
    }

    .main-header__button-logout {
      max-width: 150px;
      margin: 0;
      background: $logoutColour;
      color: white;
      &:hover,
      &:focus {
        background: $cancelHoverColour;
        transition: $buttonHoverTransitionIn;
      }
    }
    .main-header__button-confirm {
      max-width: 150px;
      margin: 0;
      background: $successColour;
      color: black;
      &:hover,
      &:focus {
        background: $successHoverColour;
        transition: $buttonHoverTransitionIn;
      }
    }
  }

  &.--header-mobile {
    position: fixed;
    justify-content: flex-start;
    top: 0;
    height: $mobileNavMenuHeight;
    padding: 0 10px;
    -webkit-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.36);
    box-shadow: 0px 7px 10px -1px rgba(0, 0, 0, 0.36);
    button.hamburger {
      margin: 0;
      width: initial;
      cursor: pointer;
      &:focus {
        background-color: transparent;
      }
      &:hover {
        background: none;
      }
      box-shadow: none;
    }
    h1.main-header__header-title {
      font-size: 18px;
      font-weight: 500;
      width: calc(100% - 70px);
      text-align: center;
    }
    button.main-header__button-notification {
      max-width: 35px;
      margin: 0;
      box-shadow: none;
      background-color: transparent !important;
    }
    button.main-header__back-button {
      max-width: 35px;
      margin: 0;
      box-shadow: none;
      background-color: transparent;
      fill: #818181;
      padding: 5px;
    }
    aside.notification-panel {
      top: $mobileNavMenuHeight;
    }
  }

  &.dark-header {
    background-color: $headerBackgroundDark;
    color: $headerTextColourLight;

    button.main-header__header-button {
      border: none;
      box-shadow: none;
    }
  }

  button.main-header__header-button {
    border: none;
    box-shadow: none;
    background: none;
  }
}
