form.form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .form__form-inner {
    max-width: $formWidth;
    width: 100%;
    margin: 0 auto;
    h4 {
      text-align: left;
      font-weight: 600;
    }
    p {
      display: block;
      text-align: left;
      line-height: 1.5rem;
    }
    a {
      text-decoration: none;
      margin: 10px 0;
    }
    input[type='checkbox'] {
      border-color: $callToActionColour;
    }
    svg {
      fill: $callToActionColour;
    }
    input::placeholder {
      color: $textLightColour;
    }

    .helper-text-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      margin-bottom: 15px;
      a {
        margin: 0;
      }
    }
    button.MuiButtonBase-root:last-child {
      max-width: 100% !important;
    }

    .form__textfield-with-buttons {
      .form__textfield-button-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        .Mui-focused {
          input {
            color: $callToActionColour;
          }
        }
        button {
          max-width: 130px;
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: $mobileWidth) {
  form.form {
    height: 100%;
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin: 20px auto;
    justify-content: start;
    .form__form-inner {
      button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
        min-width: 100%;
      }
    }
  }
}
