dialog.alert-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $alertSuccessColour;
  overflow: auto;
  position: absolute;
  border: none;
  border-bottom: 1px solid white;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  &.alert-panel--is-error {
    background: $alertErrorColour;
  }
  button.alert-panel__close {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    top: 30px;
    right: 20px;
    width: 15px;
    min-width: 15px;
    height: 15px;
    img {
      width: 100%;
    }
    &:hover {
      cursor: pointer;
    }
  }
  span {
    text-align: center;
    padding: 40px 0;
    color: white;
    font-weight: 600;
  }
}
