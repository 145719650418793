.mui-table {
  table.MuiTable-root {
    border-collapse: collapse;

    td,
    tr {
      height: 67px;
    }

    td {
      position: relative;
    }

    .MuiTableCell-stickyHeader,
    .MuiTableCell-head {
      border: 1px solid white;
      background: transparent;
      color: $callToActionColour;
      font-weight: 600;
    }
    tr.edit-row {
      position: relative;

      button.button-edit-row {
        padding: 10px 5px;
        max-height: 36px;
        max-width: 32px;
        position: absolute;
        top: 5px;
        right: 10px;

        img,
        svg {
          max-width: 16px;
          max-height: 16px;
          display: block;
          margin: auto auto;
        }

        &.--unselect {
          background-color: $cancelColour;

          svg {
            fill: $cancelIconColour;
          }

          &:hover {
            background-color: $cancelHoverColour;
          }
        }
      }
    }

    td.capitalized-cell {
      text-transform: capitalize;
    }

    td.edit-cell {
      border: none;
      padding-left: 4px;
    }
    td.MuiTableCell-root {
      padding: 5px 15px;
      min-width: 100px;
      border: 1px solid white;
      color: white;
      font-size: 1rem;

      &.--booking-changed {
        color: $callToActionColour;
        font-weight: 600;
      }

      &.cell-with-button {
        width: 200px;

        &.--booking-changed {
          color: $callToActionColour;
          font-weight: 600;
        }

        @media (max-width: $mobileWidth) {
          width: initial;
        }
        button {
          width: 100%;
          @media (max-width: $mobileWidth) {
            font-size: 12px;
            margin: 0;
          }
        }
      }
      .mui-table__booking-type-image {
        width: auto;
        height: 25px;
        min-height: 25px;
        display: block;
        margin: 0 auto;
        fill: $assignBookingIcon;

        &.--in-transit {
          fill: $inTransitBookingIcon;
        }
      }
    }
    &.--assignment-table {
      .assignment-warning-cell {
        border: none;
        max-width: 35px;
      }
    }

    &.--table-without-buttons {
      td.MuiTableCell-root {
        padding: 22px 15px;
      }
    }
  }
}
@media (max-width: $mobileWidth) {
  .mui-table {
    table.MuiTable-root {
      td,
      tr {
        height: 37px;
        .MuiTableCell-root {
          padding: 8px;
          line-height: 1.1;
          font-size: 14px;
        }
      }
      .MuiTableCell-head {
        line-height: 1.1;
      }
    }
  }

  button.button-edit-row {
    position: absolute;
    margin: 0;
  }
}
