.manage-vehicles-view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  height: calc(100vh - 80px);

  &__table-heading {
    position: static;
  }

  &__table-container {
    overflow-x: auto;
    height: calc(100vh - 325px);
  }

  &__table-container-contents {
    max-width: 500px;
  }

  &__manage-vehicles-container {
    justify-content: flex-start;
    margin: 0;
    width: 100%;
    max-height: 100vh;

    h6 {
      margin: 40px 0;
      text-align: left;
      font-weight: 600;
      color: $callToActionColour;
    }

    .dropdown-menu {
      &.--manage-vehicles {
        position: absolute;
        right: 10px;
        top: 50px;
        z-index: 1;
      }

      .dropdown-option {
        box-shadow: none;
        &.--disable-hover-highlight:hover {
          background-color: white;
        }
      }
    }

    .filter-text-input {
      height: 42px;
      width: 400px;
      max-width: 100%;
      border-radius: 10px;
      padding-left: 10px;
      border: none;
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 15px 0;
      border-bottom: 1px solid $underlineGrey;

      h6 {
        margin: 40px 0 10px;
        text-align: left;
        font-weight: 600;
        line-height: 1rem;
        color: $callToActionColour;
        width: 100%;
      }

      .button {
        margin: 10px 10px;
        line-height: 1rem;
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  .manage-vehicles-view {
    align-items: flex-start;
    height: 100vh;
    padding: 0;

    &__table-container {
      height: calc(100vh - 350px);
      max-width: 100%;
    }

    .mui-table table.MuiTable-root td,
    .mui-table table.MuiTable-root tr {
      height: 47px;
    }

    &__manage-vehicles-container {
      header {
        display: block;
        margin-bottom: 60px;

        h6 {
          display: block;
          line-height: 1.6rem;
          width: 100%;
        }

        .button {
          float: left;
          margin-left: 0;
        }
      }

      .dropdown-menu {
        &.--manage-vehicles {
          top: 40px;
        }
      }
    }
  }
}
