main.add-booking-view {
  .add-booking-view__booking-type-button-container {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
}

@media (max-width: $mobileWidth) {
  main.add-booking-view {
    .add-booking-view__booking-type-button-container {
      flex-direction: column;
    }
  }
}
