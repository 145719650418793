main.assign-booking-view {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  align-items: flex-start;
  justify-content: flex-start;

  .assign-booking-view__booking-summary {
    width: 100%;
    background: $pageBackgroundLight;
    .container {
      overflow-x: auto;
      h6 {
        margin: 40px 0;
        text-align: left;
        font-weight: 600;
      }
    }
  }

  .assign-booking-view__assignment-table-container {
    flex: 1;
    overflow: auto;
    width: 100%;

    .assign-booking-view__assignment-table-header {
      max-width: 800px;
      display: flex;
      justify-content: space-between;
      @media (max-width: $mobileWidth) {
        flex-direction: column;
      }
      .warning-information-container {
        display: flex;
        align-items: center;
        margin-left: 5px;
        @media (max-width: $mobileWidth) {
          margin-left: 0;
        }
      }
    }
    .assign-booking-view__assignment-table-body {
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
  .assign-booking-view__booking-notes {
    width: 100%;
  }

  .assign-booking-view__notes-form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .assign-booking-view__notes-form-button {
    margin: 0 0 0 20px;
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .assign-booking-view__add-note-mobile-icon {
    height: 30px;
    width: auto;
  }

  .MuiInputBase-root,
  .MuiFormControl-root {
    max-width: 910px;
  }
  .MuiOutlinedInput-multiline {
    max-width: 910px;
    margin-right: 20px;
  }
}

@media (max-width: $mobileWidth) {
  main.assign-booking-view {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: calc(100vh - 60px);

    .assign-booking-view__notes-form-button {
      width: 40px;
      height: 40px;
    }
  }
}
