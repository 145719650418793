.booking-note-list {
  max-height: calc(100vh - 475px);
  min-height: 400px;
  overflow-y: auto;
  max-width: 910px;

  &__empty-list {
    margin-left: 20px;
  }
}

.booking-note {
  display: block;
  position: relative;
  max-width: 910px;

  padding: 15px 0 5px 0;
  border-radius: 5px;
  color: rgb(77, 77, 77);
  font-size: 16px;

  &__note-user {
    position: absolute;
    font-style: italic;
    top: 0;
    left: 0px;
    font-size: 12px;
    font-style: italic;
    color: #fff;
  }

  &__note-timestamp {
    position: absolute;
    top: 0;
    right: 0px;
    font-style: italic;
    font-size: 12px;
    color: #fff;
  }

  &__note-content {
    display: block;
    width: 100%;
    margin: 5px auto 0;
    background: #e4e4e4;
    padding: 5px 10px;
    border-radius: 5px;
    color: #000;
    font-size: 14px;

    &--dark {
      background: #252e33;
      border-radius: 5px;
      color: #fff;
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 5px 10px;
      font-size: 14px;
    }
  }

  &.--haulier {
    margin: 15px 0;

    .booking-note__note-content {
      padding: 10px 20px;
    }

    .booking-note__note-timestamp {
      right: 20px;
    }

    .booking-note__note-user {
      left: 20px;
    }
  }
}

@media (max-width: $mobileWidth) {
  .booking-note-list {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
  }
}
