.country-phone-combined-input {
  display: flex;
  justify-content: space-between;
  [role='combobox'] {
    width: 30%;
  }
  .country-phone-combined-input__number-input {
    width: 69%;
  }
}
