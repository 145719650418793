.connect-haulier {
  margin: 10px 10px;
  padding-bottom: 10px;
  height: max-content;
  justify-content: flex-start;
  margin: 0;

  h6 {
    margin: 40px 0 20px;
    text-align: left;
    font-weight: 600;
    color: $callToActionColour;
    border-bottom: 1px solid $detailsBorderColour;
    width: 100%;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__table-container {
    max-width: 50%;
    flex: 1 auto;
    &:first-of-type {
      margin-right: 20px;
    }
    h6 {
      border: none;
      margin: 20px 0;
    }

    .table-row {
      &__match-cell-with-button {
        height: 67px;
      }
    }

    .MuiTableCell-body {
      padding: 5px 15px;
    }
  }

  &__filter {
    width: 100%;
    max-width: 700px;
    height: max-content;
    position: relative;
    margin-bottom: 20px;
  }

  &__text-filter {
    display: inline-block;
  }

  &__filter-text-box {
    height: 42px;
    width: 400px;
    max-width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    border: none;
  }

  @media (max-width: 950px) {
    &__text-filter {
      width: 100%;
    }

    &__filter-text-box {
      width: 100%;
    }
    &__show-connected-checkbox {
      margin-top: 10px;
    }
  }

  &__key {
    display: inline-block;
  }
}

@media (max-width: $fullPageTableWidth) {
  .connect-haulier {
    &__table-container {
      display: block;
      width: 100%;
      max-width: none;
      margin: 0 auto;
      &:first-of-type {
        margin-right: 0;
      }
    }
  }
}
