// card
$cardBorder: #ffc400;

$cardBackground: #262f32;
$cardBackgroundHover: #242a55;
$cardBackgroundPlanned: #ffffff;
$cardBackgroundPlannedHover: #c5c5c5;
$cardBackgroundPlannedIntransit: #f1f1f1;
$cardBackgroundPlannedIntransitHover: #c5c5c5;
$cardBackgroundRecurring: #fcbd00;
$cardBackgroundRecurringHover: #fada7c;
$cardBackgroundRecurringTransit: #f1cf70;
$cardBackgroundRecurringTransitHover: #f1cf70b3;
$cardDetailsBorderPlanned: #fcbc00;
$cardDetailsBorderRecurring: #253137;

// card icons
$unplannedBookingIcon: #fcbd03;
$plannedBookingIcon: #65b22e;
$recurringBookingIcon: #323a33;
$lateCueIcon: #af0404;
$plannedInTransitIcon: #a3ca88;
$recurringInTransitIcon: #868b8b;
$lateCueInTransitIcon: #c97070;

// assign delviery icons
$assignBookingIcon: #fcbd03;
$inTransitBookingIcon: #64b22f;

// backgrounds
$dashboardBackground: #dadada;
$operationOverviewBackground: #ffffff;
$firstLastColumnBackground: #666666;
$pageBackground: #253137;
$pageBackgroundLight: #313c40;
$headerBackgroundDark: #262e33;
$headerBackgroundLight: #dadada;
$navBackground: #253137;
$sitesSidebarBackground: #a3a3a3;
$plannedBookingModalDetailBackground: #dfdfdf;
$recurringBookingModalDetailBackground: #fcbc01;
$bookingInProgressBannerBackground: #272e32;
$bookingInTransitBannerBackground: #fcbc00;

// notifications
$ctaNotification: #fcbc00;
$errorMessageBackground: #650000;

// text
$textLightColour: #969696;
$textErrorColour: white;
$textHelperColour: #c5c5c5;
$headerTextColourLight: #ffffff;
$headerTextColourDark: #262e33;
$textDisabledColour: #7c7c7c;
$textWarningColour: #8f0000;
$textPrimary: #fcbc00;
$textPlannedBookingCard: #4b5559;
$textPlannedInTransitBookingCard: #868d8f;
$textUnplannedBookingCard: white;
$textRecurringBookingCard: #4b5559;
$textLateWarning: #af0404;
$textLateInTransitWarning: #c97070;
$columCapacityColour: #666666;

// form
$formBorderColour: #ffffff;
$formBorderColourHover: #fcbc00;
$formBorderDisabledColour: #7c7c7c;

// buttons
$callToActionColour: #fcbc00;
$callToActionColourHoverColour: #ffdc67;
$buttonGradient: linear-gradient(to right, #616800, #21b573);
$buttonGradientHover: linear-gradient(to right, #188852, #03d276);
$buttonCancelGradient: linear-gradient(to right, #710000, #af0000);
$completeColour: #68e018;
$successColour: #64b22f;
$successHoverColour: #86d650;
$cancelColour: #8f0000;
$cancelIconColour: #ffffff;
$cancelHoverColour: #912020;
$buttonDisabled: #886600;
$logoutColour: #af0000;
$buttonInfoColour: #e4e4e4;

//alerts
$alertSuccessColour: #177700;
$alertErrorColour: #650000;

// misc
$headerBorderColour: #cfcfcf;
$detailsBorderColour: #6d6d6d;
$notificationButtonDotColour: #8f0000;
$hamburgerColour: #818181;
$underlineGrey: #858585;
// sizes
$smallDesktopWidth: 992px;
$tabletWidth: 786px;
$mobileWidth: 650px;
$smallMobileWidth: 450px;
$mobileNavMenuHeight: 50px;
$desktopHeaderHeight: 80px;
$formWidth: 440px;
$fullPageTableWidth: 1060px;

//transitions
$buttonHoverTransitionIn: all 0.2s ease-in-out;
$buttonHoverTransitionOut: all 0.3s ease;
