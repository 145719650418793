main.add-organisation-view {
  .add-organisation-view__confirmation-fieldset {
    position: relative;
    margin: 30px 0;
    span {
      display: block;
      width: 100%;
      text-align: left;
      font-weight: 800;
      @media (max-width: $mobileWidth) {
        border-bottom: 1px solid rgb(141, 141, 141);
      }
    }
    input.add-organisation-view__confirmation-input {
      background: none;
      display: block;
      width: 70%;
      margin: 2px 0;
      padding: 5px;
      border: none;
      border-bottom: 1px solid $callToActionColour;
      font-size: 1rem;
      color: white;
      & label {
        font-weight: 200;
      }
      &:disabled {
        outline: none;
        border-bottom: 1px solid transparent;
        color: white;
        background: none;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .add-organisation-view__confirmation-button-container {
      position: absolute;
      width: 30%;
      top: 22px;
      right: 0;
      display: flex;
      justify-content: flex-end;
      @media (max-width: $mobileWidth) {
        position: static;
        width: 100%;
        top: 22px;
        right: 0;
        display: flex;
        justify-content: flex-start;
      }
      button {
        max-width: 80px;
        min-width: initial;
        margin: 0 3px;
        width: 100%;
        padding: 5px;
        border-radius: 5px;
        color: black;
        border: none;
        &:focus {
          outline: none;
        }
        &.--button-save,
        &.--button-edit {
          background: $callToActionColour;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          color: black;
          img {
            width: 10px;
          }
        }
        &.--button-save {
          background: $successColour;
        }
        &.--button-cancel {
          background: $cancelColour;
        }
      }
    }
  }
  .add-organisation-view__two-button-container {
    display: flex;
    justify-content: space-between;
    button {
      margin: 0;
      width: 49%;
    }
  }
  ul {
    li {
      margin-bottom: 15px;
    }
  }
}
