// view animations
.view-fade-enter {
  opacity: 0;
}
.view-fade-enter-done,
.view-fade-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

// schedule card animations
.booking-card-enter {
  opacity: 0;
  transform: scale(0.9);
}
.booking-card-enter-done {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
}

// form field animations
form {
  .field-fade-enter {
    opacity: 0;
  }

  .field-fade-enter-done {
    opacity: 1;
    transition: all 800ms cubic-bezier(0.51, 0.07, 1, 1);
  }
}

form {
  .formfield-button-container {
    overflow: hidden;
  }
  .formfield-button-container-enter {
    height: 100%;
    transition: all 100ms ease;
  }
  .formfield-button-container-enter-done {
    height: 100%;
    transition: all 100ms ease;
  }
}

.mobile-nav-slide-enter-done {
  left: 0 !important;
  transition: left 100ms ease-in;
}

// alert panel
.alert-panel-enter {
  opacity: 0;
}
.alert-panel-enter-done {
  opacity: 1;
  transition: opacity 100ms ease-in;
}
