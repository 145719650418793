main.manage-user-view {
  max-height: calc(100vh - 80px);
  .manage-user-view__user-type-button-container {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    button.--square-image-button {
      position: relative;
      z-index: 0;
      justify-content: flex-start;
      padding: 5px;
      h4 {
        margin: 0 0 auto 0;
        color: white;
        font-weight: 400;
      }
      img {
        margin-bottom: auto;
      }
      p {
        font-size: 0.8rem;
        margin: 0 0 15px 0;
        color: white;
      }
      div.manage-user-view__info-button {
        display: flex;
        position: absolute;
        bottom: 5px;
        right: 5px;
        padding: 0;
        background: none;
        z-index: 1;
        color: $buttonInfoColour;

        svg.MuiSvgIcon-root {
          width: 30px;
          height: 30px;
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }
      }
      .manage-user-view__info-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 5px;
        height: 100%;
        text-align: left;

        ul {
          padding-left: 15px;
          margin: 0;

          li {
            margin-bottom: 5px;
            font-size: 0.8rem;
            color: white;
          }
        }
      }

      &.--active {
        h4 {
          color: $callToActionColour;
        }
        div.manage-user-view__info-button {
          height: 30px;
          color: $callToActionColour;
        }
      }
    }
  }
  .manage-user-view__two-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      width: 48%;
    }
  }

  .manage-users {
    justify-content: flex-start;
    margin: 0;
    width: 100%;
    max-height: 100vh;

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      margin: 15px 0;
      border-bottom: 1px solid $underlineGrey;

      h6 {
        margin: 40px 0 10px;
        text-align: left;
        font-weight: 600;
        line-height: 1rem;
        color: $callToActionColour;
        width: 100%;
      }

      .button {
        margin: 10px 0;
        line-height: 1rem;
      }
    }

    &__table-heading {
      position: static;
    }

    &__heading-container {
      margin-bottom: 10px;
    }

    &__heading-button-container {
      width: 350px;
      display: flex;
      justify-content: flex-end;

      button:nth-of-type(2) {
        margin-left: 10px;
      }
    }

    &__sub-heading-container {
      display: block;
      margin: 10px 0;
      flex: 0 0 100%;
    }

    &__table-container {
      overflow-x: auto;
      height: calc(100vh - 325px);
    }

    &__table-row {
      height: 47px;
    }

    &__table-container-contents {
      max-width: 768px;

      &.--two-column {
        max-width: 550px;
      }
    }

    h6 {
      margin: 40px 0 0 0;
      text-align: left;
      font-weight: 600;
      color: $callToActionColour;
    }

    .dropdown-menu {
      &.--manage-users {
        position: absolute;
        right: 10px;
        top: 50px;
        z-index: 1;
      }

      .dropdown-option {
        box-shadow: none;
        &.--disable-hover-highlight:hover {
          background-color: white;
        }
      }
    }

    .filter-text-input {
      height: 42px;
      width: 400px;
      max-width: 100%;
      border-radius: 10px;
      padding-left: 10px;
      border: none;
    }
  }
}

@media (max-width: $mobileWidth) {
  main.manage-user-view {
    max-height: 100vh;
    .manage-user-view__user-type-button-container {
      flex-direction: column;
    }

    .manage-user-view__two-button-container {
      flex-direction: column;

      button {
        width: 100%;
      }
    }

    .manage-users {
      &__overflowing-table-cell {
        overflow: hidden;
        text-overflow: ellipsis;

        height: 87px;

        max-width: 200px;
      }

      &__table-container {
        height: calc(100vh - 350px);
      }

      &__header {
        flex-direction: column;
      }

      &__heading-button-container {
        width: 100%;
        justify-content: space-around;
        background-color: $headerBackgroundLight;
        flex-direction: column-reverse;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;

        button {
          margin: 0;
        }

        button:nth-of-type(2) {
          margin-left: 0px;
        }
      }

      .edit-user-row td {
        padding: 8px;
        height: 47px;
      }

      header {
        display: block;
        margin-bottom: 60px;

        h6 {
          display: block;
          line-height: 1.6rem;
          width: 100%;
        }

        .button {
          float: left;
        }
      }

      .dropdown-menu {
        &.--manage-users {
          position: absolute;
          right: 10px;
          top: 40px;
          z-index: 1;
        }

        .dropdown-option {
          &.--disable-hover-highlight:hover {
            background-color: white;
          }
        }
      }
    }
  }
}
