.booking-keys-container {
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .booking-keys-container__key {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    width: 100%;
    max-width: 230px;
    .booking-keys-container__key-icon {
      height: 28px;
      width: auto;
      fill: $assignBookingIcon;

      &.--in-transit {
        fill: $inTransitBookingIcon;
      }
    }
    span {
      margin-left: 10px;
      font-weight: 600;
    }
  }
}

@media (max-width: $mobileWidth) {
  h6 {
    margin-bottom: 20px;
  }
  .booking-keys-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .booking-keys-container__key {
    width: 100%;
    margin: 10px;
  }
}
