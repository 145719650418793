.react-datepicker-wrapper {
  width: 100%;
  display: block;
  .react-datepicker__input-container {
    width: 100%;
  }
  button.date-button {
    position: relative;
    outline: none;
    border: none;
    height: 40px;
    margin: 0;
    max-width: 100%;
    background: $pageBackground;
    width: 100%;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-weight: 200;
    letter-spacing: 3px;
    font-size: 14px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      border-color: $callToActionColour transparent;
      border-style: solid;
      border-width: 6px 6px 0px 6px;
      height: 0px;
      width: 0px;
    }
    &:hover {
      cursor: pointer;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.react-datepicker {
  z-index: 2;
  background: $pageBackground;
  background-color: $pageBackground !important;
  button.react-datepicker__navigation {
    &.react-datepicker__navigation--next {
      border-left-color: $callToActionColour;
    }
    &.react-datepicker__navigation--previous {
      border-right-color: $callToActionColour;
    }
  }

  .react-datepicker__header,
  .react-datepicker__month {
    background: $pageBackground;
  }
  .react-datepicker__header {
    .react-datepicker__day-name,
    .react-datepicker__current-month {
      color: white;
    }
    .react-datepicker__current-month {
      border-bottom: 1px solid white;
      padding-bottom: 5px;
    }
  }
  .react-datepicker__month {
    margin: 0;

    .react-datepicker__day {
      color: white;
      &:hover {
        color: black;
      }
      &.react-datepicker__day--today {
        color: $callToActionColour;
      }
      &.react-datepicker__day--selected {
        color: black;
        background-color: $callToActionColour;
        border-radius: 50%;
      }
      &.react-datepicker__day--disabled {
        color: grey;
      }
    }
  }
  .react-datepicker__today-button {
    background: $callToActionColour;
    height: 35px;
    margin: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
  button.view-today-button {
    margin-top: 0px;
  }
}
