main.schedule-view {
  display: flex;
  height: calc(100vh - 80px);

  .schedule-view__column-container {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: $operationOverviewBackground;
  }

  .schedule-view__booking-schedule-link-button {
    width: 32px;
    height: 32px;
    padding: 4px;
    margin: 0;
  }
}

@media (max-width: $mobileWidth) {
  main.schedule-view {
    height: 100vh;
  }
}
