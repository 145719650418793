.dropdown-menu {
  background-color: white;
  padding: 5px 0;
  border-radius: 5px;
  border-top-right-radius: 0;

  .dropdown-option {
    border-radius: 0;
    margin: 0;
    padding: 10px 20px;
    background-color: white;
    font-weight: 400;
    font-size: 16px;

    &:hover {
      background-color: $callToActionColour;
    }
  }

  &__loading-circle {
    height: 18px;
    padding: 0;
    margin: 0;
  }
}
