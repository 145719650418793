main.confirmation-code-view {
  button {
    max-width: 100% !important;
  }
  .confirmation-code-view__resend-code-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      margin-left: 10px;
      font-size: 0.8em;
    }
  }

  .confirmation-code-view__input-container {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0;
    .confirmation-code-view__input {
      margin: 0;
      display: inline;

      & > div {
        border-radius: 0;
      }

      input {
        padding: 5px;
        height: 50px;
        text-align: center;
        font-weight: 600;
        font-size: 1.3rem;
      }
      fieldset {
        border-color: white;
        padding-left: 0;
        &:first-child {
          border-top-left-radius: 5px !important;
        }
      }
    }
  }
}
