.recurrent-booking-date-selection {
  height: max-content;
  width: 100%;
  position: relative;

  .event-icon {
    position: absolute;
    right: 10px;
    top: 32px;
  }

  &__date-selector {
    width: max-content;
    margin: 0 auto;
    border: 1px $cardBorder solid;
    border-radius: 5px;
  }
}
