.booking-assignment-table-container {
  padding-bottom: 100px; //Ensures the bottom dropdown menu completely visible when open
}

.dropdown-menu {
  &.--assign-booking {
    position: absolute;
    right: 10px;
    top: 50px;
    z-index: 1;
  }
}

.cell-with-button {
  &__value-beside-button {
    padding-right: 46px;
  }
}
