.booking-schedule-view {
  display: flex;
  flex-direction: column;
  h6 {
    margin: 20px 0;
  }
  &__heading {
    display: inline-block;
  }

  &__upcoming-bookings-table-site-entrance {
    text-transform: capitalize;
  }

  .mui-table table.MuiTable-root {
    td,
    tr {
      height: 40px;
    }
  }

  tr th:first-of-type {
    width: 100px;
  }

  .booking-schedule-view__header {
    display: flex;
    justify-content: flex-start;

    h6 {
      margin: 20px 0;
    }
  }

  button.booking-schedule-view__overview-link-button {
    padding: 0;
    margin: 20px 10px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg.booking-schedule-view__overview-link-icon {
    width: 100%;
    height: auto;
    max-width: 16px;
    max-height: 16px;
    top: 8px;
    left: 8px;
    margin: auto auto;
    fill: #263138;
  }

  .button {
    margin: 0;
  }

  .view-today-button {
    display: inline-block;
    margin-left: 10px;
  }

  &__day-picker-container.react-datepicker-wrapper {
    max-width: 800px;
    display: flex;
    justify-content: flex-start;
    .react-datepicker-wrapper {
      max-width: 200px;
      margin-right: 10px;

      .date-button {
        display: inline-block;
        margin-right: 10px;
        border: 1px solid $callToActionColour;
        color: $callToActionColour;
        font-weight: 600;
      }
    }
  }

  .booking-schedule-view__table-container {
    margin-top: 10px;
    table {
      &.--upcoming-bookings-table {
        max-height: 400px;
        margin-top: 20px;
      }

      &__upcoming-bookings-table-heading {
        background-color: $pageBackground !important;
        border: 1px solid #fff !important;
        z-index: 0;
        position: sticky;
      }
      &.--completed-bookings-table {
        max-width: 900px;
        max-height: 400px;
      }
    }
  }

  .completed-booking__completed-at,
  .completed-booking__rejected-at {
    filter: brightness(40%);
  }
}

@media (max-width: $mobileWidth) {
  .booking-schedule-view {
    .view-today-button {
      display: block;
    }
  }
}
