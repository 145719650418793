.booking-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $cardBackground;
  height: max-content;
  width: 100%;
  border-radius: 10px;
  margin: 8px auto 0 auto;
  color: white;
  text-align: left;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

  &.--in-transit {
    border: 2px solid #fcbc00;
  }

  &__icon {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    height: 20px;
    width: auto;
    max-width: 100px;
    fill: #fcbd00;
  }

  &__job-name {
    font-style: italic;
    font-size: 10px;
    display: block;
  }

  &__card-details {
    margin: 10px 0;
  }

  &__vrm {
    display: block;
    font-size: 0.8rem;
  }

  &__material-container {
    display: inline-block;
    font-size: 0.8rem;
    word-break: break-all;
    hyphens: auto;
    width: 100%;
  }

  &__material-name {
    display: inline-block;
    width: max-content;
    max-width: 100%;
  }

  &__status {
    display: block;
    font-size: 0.8rem;
    position: relative;
    min-height: 5px;

    &--late {
      display: block;
      font-size: 0.8rem;
      position: relative;
      min-height: 5px;

      color: $textLateWarning;
      padding-right: 25px;
    }
  }

  &__late-cue {
    fill: $lateCueIcon;
    float: right;
    position: absolute;
    top: 0;
    right: 5px;
    height: 0.8rem;
  }

  &__booking-slot {
    display: inline-block;
  }

  &__first-destination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: capitalize;
    font-size: 0.8rem;
  }

  &__first-destination-icon {
    height: 20px;
    width: auto;
    margin-right: 5px;
  }

  &.--completed {
    border: 2px solid green;
  }
  &.--rejected {
    border: 2px solid red;
  }

  &__card-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    font: italic normal 600 12px/17px;

    &.--in-progress {
      background-color: $bookingInProgressBannerBackground;
      color: white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &.--in-transit {
      background-color: $bookingInTransitBannerBackground;
    }

    .booking-card__footer-text {
      font-style: italic;
      font-size: 0.8rem;
      padding: 5px 0;
    }
  }

  .booking-card__inner {
    margin: 15px;

    h6 {
      padding: 0;
      margin: 0;
    }
  }

  &:hover {
    cursor: pointer;
    transition: all 0.1s linear;
    background: $cardBackgroundHover;
  }

  // card modifiers
  &.--planned {
    background-color: $cardBackgroundPlanned;
    color: black;

    &:hover {
      background: $cardBackgroundPlannedHover;
    }

    .booking-card__icon {
      fill: $plannedBookingIcon;
    }
  }

  &.--recurrent {
    background-color: $cardBackgroundRecurring;

    .booking-card__icon {
      fill: $recurringBookingIcon;
    }

    &:hover {
      background: $cardBackgroundRecurringHover;
    }

    // In Transit recurrent bookings
    &.--in-transit {
      border: 3px $cardBorder solid;
      color: #82898c;

      background-color: $cardBackgroundRecurringTransit;

      &:hover {
        background: $cardBackgroundRecurringTransitHover;
      }

      .booking-card__icon {
        fill: $recurringInTransitIcon;
      }
    }
  }
}
