.progress-bar {
  max-width: $formWidth;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 50px auto;
  .progress-bar__circle {
    width: 35px;
    height: 33px;
    border: 1px solid;
    border-radius: 50%;

    &.progress-bar__circle--complete {
      background: $completeColour;
      border: 1px solid $completeColour;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 20px;
      }
    }
    &.progress-bar__circle--current {
      background: $callToActionColour;
      border: 1px solid $callToActionColour;
    }
  }
  span {
    height: 3px;
    width: 80px;
    background: white;
    &.complete {
      background: $completeColour;
    }
    &:last-child {
      display: none;
    }
  }
}
@media (max-width: $mobileWidth) {
  .progress-bar {
    padding: 5px;
    .progress-bar__circle {
      width: 30px;
      height: 28px;
    }
  }
}
