main.dashboard-view {
  padding-top: 0;

  .dashboard-view__dashboard-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 40px;
    a.dashboard-view__dashboard-box-container {
      width: 100%;
      max-width: 250px;
      display: block;
      margin: 10px;
      .dashboard-view__dashboard-box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        width: 100%;
        height: 250px;
        margin: 0 auto;
        border: 3px solid #c5c5c5;
        border-radius: 5px;
        img {
          max-width: 150px;
          width: 100%;
        }
      }
      button.dashboard-button {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  main.dashboard-view {
    padding-top: 50px;
    .dashboard-view__dashboard-container {
      overflow: auto;
      flex-direction: column;
      justify-content: flex-start;

      padding: 10px;

      a.dashboard-view__dashboard-box-container {
        width: 100%;
        a {
          font-size: 1rem;
        }
      }
    }
  }
}
