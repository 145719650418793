.button-reset {
  border: none;
  outline: none;
}

.button,
button.MuiButton-root {
  @extend .button-reset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  margin: 10px auto;
  width: 100%;
  max-width: 250px;
  border-radius: 5px;
  background-color: $callToActionColour;
  color: black;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  font-size: 14px;
  transition: $buttonHoverTransitionOut;
  box-shadow: 0px 3px 6px #00000029;

  &--click-wait {
    .loading-circle {
      height: 16px;
      margin: 0 auto;
    }
  }

  &:hover,
  &:focus {
    background-color: $callToActionColourHoverColour;
    cursor: pointer;
    transition: $buttonHoverTransitionIn;
  }
  &:disabled {
    background: $buttonDisabled;
    color: black;
  }
  &.--outline {
    background: none;
    border: 1px solid $callToActionColour;
    color: white;
    text-transform: capitalize;
    &:hover,
    &:focus {
      background: $callToActionColour;
      color: $pageBackground;
      transition: $buttonHoverTransitionIn;
    }
  }

  // modifiers
  &.--full-width {
    max-width: 100%;
  }
  &.--small {
    max-width: 150px;
    width: 100%;
  }

  &.--warning {
    background: $cancelColour;
    color: white;
    &:hover,
    &:focus {
      background: $cancelHoverColour;
      transition: $buttonHoverTransitionIn;
    }
  }
  &.--success {
    background: $successColour;

    &:hover,
    &:focus {
      background: $successHoverColour;
      transition: $buttonHoverTransitionIn;
    }
  }
  &.--selected {
    background: $successColour;
  }

  &.--square-image-button {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px;
    width: 100%;
    height: 200px;
    max-width: 200px;
    background: none;
    border: 1px solid white;
    color: white;
    font-size: 16px;
    font-weight: 400;
    img {
      width: 100%;
      max-height: 100px;
    }
    &.--selected {
      border-color: $callToActionColour;
      color: $callToActionColour;
    }

    // hover focus states.
    &:hover,
    &:focus {
      cursor: pointer;
      border-color: $callToActionColour;
      transition: $buttonHoverTransitionIn;
      background-color: transparent;
      color: $callToActionColour;
      img {
        fill: $callToActionColour;
      }
    }
  }
}

// override default focus state for a tags and button role elements
[role='button'],
a {
  &:focus {
    outline-color: $callToActionColour;
  }
}
