.u-helper-text {
  display: inline-block;
  color: $textHelperColour;
  font-size: 0.6rem;
  text-align: left;
  &:hover {
    color: $callToActionColour;
    transition: color 0.3s ease;
  }
}

.u-link-button {
  @extend a;
  background: none;
  display: inline;
  min-width: initial;
  &:hover {
    background: none;
  }
}
.u-block-link {
  display: block;
}
.u-highlight-title {
  color: $callToActionColour;
}

.u-sub-header {
  display: block;
  font-style: italic;
  font-size: 12px;
  text-align: left;
}
.u-border-bottom {
  border-bottom: 1px solid white;
}
.u-text-notavailable {
  color: $textDisabledColour;
  font-size: 1rem;
  font-style: italic;
}
.u-text-bold {
  font-weight: 600;
}
.u-text-italic {
  font-style: italic;
}
.u-text-yellow {
  color: $callToActionColour;
}
.u-text-center {
  text-align: center;
}
.u-text-capitalise {
  text-transform: capitalize;
}

.MuiFormControl-root.u-text-capitalise {
  input {
    text-transform: capitalize;
  }
}
