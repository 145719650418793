.password-requirements-container {
  text-align: left;
  h4 {
    margin: 0;
    font-weight: 400;
    font-size: 1.2rem;
  }
  ul {
    padding-left: 20px;
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      list-style: none;
      margin-bottom: 5px;
      color: white;
      height: 20px;
      &::before {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        font-weight: 800;
      }
      &.--valid {
        &::before {
          color: rgb(0, 184, 0);
          content: '\2713';
        }
      }
      &.--invalid {
        &::before {
          content: '\292B';
        }
      }
    }
  }
}
