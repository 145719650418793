.amplify-session-container {
  &__loading-circle-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-circle {
    padding-top: 40px;
  }
}
