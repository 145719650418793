* {
  box-sizing: border-box;
}
html,
body,
#root,
.App {
  height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $pageBackground;
  color: white;
  font-size: 1rem;
}
main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;

  @media screen and (max-width: $mobileWidth) {
    padding-top: $mobileNavMenuHeight;
  }

  &.--mobile-full-page {
    @media screen and (max-width: $mobileWidth) {
      padding-top: 0;
    }
  }

  &.loading {
    justify-content: center;
  }
  &.table-view {
    justify-content: flex-start;
    margin: 0;
    h6 {
      margin: 40px 0;
      text-align: left;
      font-weight: 600;
      color: $callToActionColour;
    }
  }
}
.container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: $tabletWidth) {
    padding: 10px;
  }
}

a {
  color: $callToActionColour;
  display: block;
  &:hover {
    cursor: pointer;
  }
}
