.column {
  min-width: 200px;
  background-color: #e4e4e4;
  overflow: auto;
  padding: 1px 0;
  margin: 4px 2px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid rgb(63, 63, 63);
    color: black;
    margin: 0 10px;
    height: 43px;
  }

  &__heading {
    padding-bottom: 4px;
    font-weight: 500;
    text-transform: capitalize;
  }

  &__capacity-label {
    margin: 5px 10px;
    text-align: right;
    font-size: 12px;
    color: $columCapacityColour;
    font-style: italic;
  }

  &__empty-column-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }

  &:first-child {
    .column__empty-column-contents-message {
      margin-bottom: 82px;

      &.--todays-empty-schedule {
        margin-bottom: 26px;
      }
    }
  }
  &:last-child {
    .column__empty-column-icon-container {
      margin-top: 20px;
    }
  }

  &__empty-column-icon-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  &__empty-column-contents-message {
    max-width: 200px;
    margin: 0 5px;
    font-size: 12px;
    text-align: center;
    color: $textLightColour;
    font-style: italic;
  }

  .column__scrolling-container {
    overflow-y: auto;
    flex-grow: 1;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &:first-child,
  &:last-child {
    margin: 0;
    padding-top: 5px;
    border-radius: 0;
    border: none;
    background: $firstLastColumnBackground;

    .column__header {
      .column__heading {
        padding-top: 0px;
      }
      border-bottom: 1px solid white;
      color: white;
    }

    .column__empty-column-contents-message {
      color: white;
    }
  }

  .react-datepicker-wrapper {
    margin-top: 5px;
  }
}
