.sites-view {
  flex-direction: row;

  &__link-button-container {
    display: flex;
    justify-content: flex-start;

    .sites-view__select-view-button {
      margin: 10px 10px 5px 0;
      width: 200px;
    }
  }

  aside.sites-view__sidebar {
    width: 100%;
    max-width: 300px;
    height: 100%;
    background-color: $sitesSidebarBackground;
    overflow-y: hidden;
    .sites-view__sidebar-inner {
      height: 100%;
      padding: 10px 0 50px 0;
      header {
        padding: 0 10px;
        h3 {
          border-bottom: 1px solid white;
          margin-bottom: 10px;
          padding-bottom: 5px;
          text-align: left;
          font-size: 14px;
        }
      }

      .sites-view__scrolling-container {
        overflow-y: auto;
        height: 100%;
        padding: 0 10px;
        padding-bottom: 40px;
        button.site-button {
          display: block;
          background: white;
          border-radius: 10px;
          height: 65px;
          max-width: 100%;
          margin: 0px auto 10px auto;
          padding: 5px 15px;
          -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.38);
          -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.38);
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.38);
          &.--selected {
            background: $callToActionColour;
          }
          p {
            display: block;
            width: 100%;
            text-align: left;
            font-weight: 600;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .sites-view__site-panel {
    background: $dashboardBackground;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: left;
    color: $headerTextColourDark;
    .sites-view__header-container {
      header.sites-view__header {
        .container {
          padding: 10px 20px;
          h3 {
            border-bottom: 1px solid $textLightColour;
            padding-bottom: 5px;
            text-align: left;
            font-size: 14px;
            color: $textLightColour;
          }
          a.button.--success {
            display: inline-block;
            margin: 10px 10px 5px 0;
            max-width: 200px;
          }
        }
      }
    }
    .sites-view__users-container {
      background: $pageBackground;
      height: 100%;
      overflow-y: scroll;

      .container {
        padding: 50px 20px 130px 20px;
        header {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 15px;
          border-bottom: 1px solid $underlineGrey;

          h3 {
            margin: 0;
            margin-top: 35px;
            margin-bottom: 5px;
            text-align: left;
            font-size: 14px;
            color: white;
            &.--text-inactive {
              color: $textDisabledColour;
            }
          }
        }

        a.button {
          margin: 10px 0;
        }

        .divider {
          height: 1px;
          width: 100%;
          background: $underlineGrey;
          margin: 10px 0;
        }
        .mui-table {
          max-width: 500px;
        }
        p {
          color: white;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  main.sites-view {
    flex-direction: column;
    aside.sites-view__sidebar {
      width: 100%;
      height: 100%;
      max-width: 100%;
      overflow: initial;
    }
    .sites-view__site-panel {
      max-height: 100%;
      height: 100%;
      width: 100%;
      overflow: initial;
      .sites-view__header-container {
        padding: 0;
        header.sites-view__header {
          text-align: center;
          a.button.--success {
            margin: 10px auto;
          }
        }
      }
    }
  }
}
