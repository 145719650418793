aside.notification-panel {
  position: absolute;
  top: $desktopHeaderHeight;
  right: 0;
  max-width: 400px;
  width: 100%;
  height: 100%;
  background: $pageBackground;
  box-shadow: -4px 0px 6px 0px rgba(50, 50, 50, 0.44);
  color: white;
  z-index: 3;
  overflow: hidden;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;

    z-index: 9;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.212);
    h3 {
      width: 100%;
      text-align: center;
      margin: 0;
    }
    button.button-close {
      background: none;
      width: 15px;
      height: 13px;
      margin: 0;
      padding: 0;
    }
  }
  .notification-panel__inner {
    padding: 20px 10px 60px 10px;
    height: 100%;
    overflow-y: scroll;
    width: 100%;

    .notification-panel__notification-container {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-top: 1px solid white;
      padding: 10px 20px 10px 30px;
      margin-bottom: 20px;

      .notification-panel__high-priority-notification {
        position: absolute;
        left: 3px;
        height: 20px;
        width: 20px;
        img {
          width: 100%;
        }
      }
      .notification-panel__notification-content {
        font-size: 12px;
        line-height: 1.5;
        max-width: 250px;
      }
      button.button.--view-notification-button {
        max-width: 70px;
        padding: 5px;
        margin: 0;
        font-size: 12px;
      }
    }
  }
}

.notification-panel.overlay {
  content: '';
  position: absolute;
  top: $desktopHeaderHeight;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.4;
  z-index: 1;
  pointer-events: all;
  &:hover {
    cursor: pointer;
  }
}

@media (max-width: $mobileWidth) {
  aside.notification-panel {
    max-width: 100%;
    height: 100vh;
    overflow: auto;
  }
}
