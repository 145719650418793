form.search-form {
  justify-content: flex-start;
  align-items: baseline;
  margin: 10px 0;
  max-width: 400px;

  .MuiFormControl-root {
    label,
    input {
      color: black !important;
    }
    div.MuiFilledInput-root {
      background-color: white;
      border-radius: 5px;

      .MuiFilledInput-input,
      label {
        color: black;
      }
    }
  }
}
