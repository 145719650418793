.booking-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  // This brings the modal in front of the header bar
  // Close button would be hidden
  @media (max-width: $mobileWidth) {
    z-index: 3;
  }

  .booking-modal__header-status {
    font-weight: 500;
    font-style: italic;
    float: right;
    text-transform: capitalize;
  }

  .booking-modal__header {
    position: relative;
    height: max-content;
    width: 100%;
  }

  .booking-modal__header-VRM {
    top: 14px;
    position: relative;
  }

  .booking-modal__header-icon {
    fill: $unplannedBookingIcon;
    width: auto;
    height: 25px;
  }

  .booking-modal__header-job-type {
    height: max-content;
    font-style: italic;
  }

  .booking-modal__header-job-type-text {
    bottom: 7px;
    right: 10px;
    position: relative;
    font-size: 12px;
  }

  .booking-modal__inner {
    position: relative;
    max-width: 1000px;
    max-height: 1000px;
    height: 100%;
    width: 100%;
    padding: 40px;
    background-color: $cardBackground;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.16);
    overflow: auto;
    color: white;
    z-index: 1;

    .booking-modal__close {
      position: absolute;
      top: 0px;
      right: 20px;
      max-width: 20px;
      background: none;
      border: none;
      box-shadow: none;

      img {
        width: 20px;
        height: 20px;
        fill: white;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .booking-modal__close-icon {
      width: 20px;
      height: 20px;
      fill: white;
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        display: flex;
        align-items: center;
        width: max-content;

        img {
          width: 33px;
          margin-left: 12px;
        }
      }

      span {
        text-align: right;
      }
    }

    .booking-modal__details-container {
      background-color: white;
      display: flex;
      justify-content: space-between;
      border-radius: 10px;
      padding: 20px;
      word-break: break-all;
      hyphens: auto;

      &.--selected-for-edit {
        border: 2px solid $cardDetailsBorderPlanned;
        padding: 18px;
      }

      .booking-modal__details {
        height: max-content;
      }

      .booking-modal__status {
        margin-top: 20px;
      }

      .booking-modal__first-destination {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: capitalize;
      }

      .booking-modal__first-destination-icon {
        margin-right: 5px;
      }

      span {
        display: block;
        color: black;
        text-align: left;
        margin: 8px 0;

        b {
          display: inline-block;
          width: max-content;
          max-width: 100%;
        }
      }

      .booking-modal__warning {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $textWarningColour;
        font-weight: 700;
        padding: 0 20px;

        .warning-icon {
          margin-left: 20px;
          height: 14px;
          fill: $textWarningColour;
        }
      }

      .booking-modal__edit-booking-button-container {
        display: flex;
      }

      .booking-modal__edit-booking-button {
        width: 150px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 16px;
        box-shadow: 0px 3px 6px #00000029;

        &.--edit {
          width: 75px;
          padding: 10px 5px;

          .booking-modal__edit-booking-button-icon {
            width: 20px;
            margin-right: 10px;
          }
        }

        &.--delete {
          background-color: #af0000;
          color: #fff;

          .booking-modal__edit-booking-button-icon {
            margin-left: 10px;
            fill: #fff;
          }
        }
      }

      .booking-modal__edit-booking-button-icon {
        width: 18px;
        height: auto;
      }
    }

    .booking-modal__notes-container {
      position: relative;
      padding: 5px 0;
      width: 100%;
      min-height: 50px;

      .booking-modal__notes-form {
        position: relative;
        padding: 0;
        height: 100%;
        width: 100%;

        .booking-modal__notes-form__loading-circle {
          .loading-circle {
            display: block;
            margin: 0;
            height: 40px;
            position: absolute;
            right: 40px;
            top: 0;
            width: 20px;
          }
        }

        .booking-modal__notes-form__input {
          display: block;
          padding: 15px 10px;
          width: 100%;
          padding: 10px;
          border-radius: 10px;
          font-size: 1rem;
          height: 40px;

          &:focus {
            display: block;
            padding: 15px 10px;
            width: 100%;
            outline: none;
            border: solid 1px black;
            border-radius: 10px;
            font-size: 1rem;
            height: 40px;
            box-shadow: 0px 0px 5px 4px $callToActionColour;
          }
        }

        .booking-modal__notes-form__button {
          position: absolute;
          right: 5px;
          top: -5px;
          width: 90px;
          height: 30px;
          line-height: 14px;
          padding: 5px;
        }
      }
    }

    .booking-modal__dropdown-container {
      border-bottom: 1px solid white;
      padding: 0 25px 5px 25px;
      overflow: hidden;
      display: block;
      position: relative;
      height: 100px;

      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }

      header {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        h4 {
          font-weight: 400;
        }
        .dropdown-arrow {
          width: 20px;
          transition: transform 0.1s ease-in-out;
          fill: #fff;
        }
      }

      .booking-modal__dropdown-content {
        max-height: 300px;
        padding: 0;

        overflow: auto;

        .activity-log-content {
          background: $plannedBookingModalDetailBackground;
          display: block;
          width: 100%;
          margin: 5px auto;
          padding: 8px 10px;
          border-radius: 5px;
          color: rgb(77, 77, 77);
        }
      }

      &.--dropdown-inactive {
        max-height: 60px;
        transition: height 0.1s ease-in;

        .dropdown-arrow {
          transform: rotate(-90deg);
          transition: transform 0.1s ease-in-out;
        }
      }

      &.--dropdown-active {
        height: auto;
        transition: height 0.1s ease-in;
      }
    }
  }

  &.--planned {
    .booking-modal__header-icon {
      fill: #65b22e;
    }

    .booking-modal__inner {
      background-color: $cardBackgroundPlanned;
      color: #222121;

      .booking-modal__close-icon {
        fill: #222121;
      }

      .booking-modal__details-container {
        background: $plannedBookingModalDetailBackground;
        border: 1px solid #c5c5c5;
      }

      .booking-modal__dropdown-container {
        border-bottom: 1px solid rgb(0, 0, 0);

        &:hover {
          cursor: pointer;
        }

        .dropdown-arrow {
          fill: #000;
        }

        .booking-note {
          &__note-user,
          &__note-timestamp {
            color: #000;
          }
        }
      }
    }
  }

  &.--recurrent {
    .booking-modal__header-icon {
      fill: $recurringBookingIcon;
    }

    .booking-modal__inner {
      background-color: $cardBackgroundPlanned;
      color: #222121;

      .booking-modal__close-icon {
        fill: #222121;
      }

      .booking-modal__details-container {
        background: $recurringBookingModalDetailBackground;

        &.--selected-for-edit {
          border: 2px solid $cardDetailsBorderRecurring;
        }

        .booking-modal__edit-booking-button {
          background-color: #253137;
          color: white;

          &.--make-changes {
            background-color: #253137;
            color: white;
          }

          &.--delete {
            margin-left: 10px;
            background-color: #af0000;
            color: white;
          }
        }

        .booking-modal__edit-booking-button-icon {
          fill: white;
        }
      }

      .booking-modal__dropdown-container {
        border-bottom: 1px solid rgb(0, 0, 0);

        .dropdown-arrow {
          fill: #000;
        }

        .booking-note {
          &__note-user,
          &__note-timestamp {
            color: #000;
          }
        }
      }
    }
  }

  .booking-modal.overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.76);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    color: white;
  }
}
